import { Environment, EnvironmentType } from '@models/environment.model';
import { b2cConfigFT } from './b2c-config';

export const environment: Environment = {
  production: true,
  apiUrl: 'https://mainservice-tbums-ft.azurewebsites.net/api/',
  apiVersionMain: 'v2',
  outcomeUrl: 'https://outcomeservice-tbums-ft.azurewebsites.net/api/',
  apiVersionOutcome: 'v1',
  positionUrl: 'https://iotfunctions-tbums-ft.azurewebsites.net/api',
  garageUrl: 'https://garageservice-tbums-ft.azurewebsites.net/api/',
  apiVersionGarage: 'v1',
  enablePlanningPage: true,
  enableCalendarPage: true,
  enableRoutePage: true,
  appInsights: {
    instrumentationKey: '926c37d5-8777-43d5-9199-6c3105ead28b',
    isEnabled: false,
  },
  language: 'sv',
  enableBilling: true,
  enableUnboundOrder: true,
  environmentType: EnvironmentType.FT,

  b2c: b2cConfigFT
};
